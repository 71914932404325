var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('nl-table',{attrs:{"left-buttons":_vm.buttons,"columns":_vm.columns,"force-update":_vm.forceUpdate,"get-data":_vm.getNotifications,"searchable":false},on:{"update:columns":function($event){_vm.columns=$event}},scopedSlots:_vm._u([{key:"created_at",fn:function({
                props: {
                    row,
                    column: { field }
                }
            }){return [_vm._v(" "+_vm._s(_vm.moment(row[field]).format('DD/MM/YYYY H:m'))+" ")]}},{key:"titre",fn:function({
                props: {
                    row,
                    column: { field }
                }
            }){return [(row.lu === 0)?_c('strong',[_vm._v(_vm._s(row[field]))]):_c('span',[_vm._v(_vm._s(row[field]))])]}},{key:"actions",fn:function({ props: { row } }){return [_c('b-tooltip',{staticClass:"mr-2",attrs:{"label":_vm.$t('notifications.learnmore')}},[_c('ButtonRouterTo',{attrs:{"to":row.destination,"icon-left":"eye","type":"is-light"}})],1),_c('b-tooltip',{attrs:{"label":_vm.$t('layout.markasread')}},[(row.lu === 0)?_c('b-button',{attrs:{"icon-left":"envelope","icon-pack":"fas","type":"is-danger"},on:{"click":function($event){return _vm.readNotification(row['id'])}}}):_c('b-button',{attrs:{"icon-left":"envelope-open","icon-pack":"fas","type":"is-success"},on:{"click":function($event){return _vm.readNotification(row['id'])}}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }