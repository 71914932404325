<template>
    <div class="box">
        <nl-table
            :left-buttons="buttons"
            :columns.sync="columns"
            :force-update="forceUpdate"
            :get-data="getNotifications"
            :searchable="false"
        >
            <template
                #created_at="{
                    props: {
                        row,
                        column: { field }
                    }
                }"
            >
                {{ moment(row[field]).format('DD/MM/YYYY H:m') }}
            </template>
            <template
                #titre="{
                    props: {
                        row,
                        column: { field }
                    }
                }"
            >
                <strong v-if="row.lu === 0">{{ row[field] }}</strong>
                <span v-else>{{ row[field] }}</span>
            </template>
            <template #actions="{ props: { row } }">
                <b-tooltip :label="$t('notifications.learnmore')" class="mr-2">
                    <ButtonRouterTo :to="row.destination" icon-left="eye" type="is-light" />
                </b-tooltip>
                <b-tooltip :label="$t('layout.markasread')">
                    <b-button
                        v-if="row.lu === 0"
                        icon-left="envelope"
                        icon-pack="fas"
                        type="is-danger"
                        @click="readNotification(row['id'])"
                    />
                    <b-button
                        v-else
                        icon-left="envelope-open"
                        icon-pack="fas"
                        type="is-success"
                        @click="readNotification(row['id'])"
                    />
                </b-tooltip>
            </template>
        </nl-table>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import ButtonRouterTo from '../components/UI/Button/ButtonRouterTo'
    import NlTable from '@/components/UI/Table/NlTable.vue'

    export default {
        components: { NlTable, ButtonRouterTo },
        title: 'notifications.title',
        data() {
            return {
                buttons: [
                    {
                        label: this.$t('layout.markallasread'),
                        icon: 'envelope-open',
                        type: 'is-warning',
                        action: this.readAll
                    },
                    {
                        label: this.$t('notifications.deleteall'),
                        icon: 'trash',
                        type: 'is-danger',
                        action: this.deleteAll
                    }
                ],
                columns: [
                    {
                        custom: true,
                        field: 'created_at',
                        label: this.$t('notifications.date')
                    },
                    {
                        custom: true,
                        field: 'titre',
                        label: this.$t('notifications.titre')
                    },
                    {
                        field: 'message',
                        label: this.$t('notifications.message')
                    },
                    {
                        custom: true,
                        field: 'actions',
                        label: 'Actions'
                    }
                ],
                forceUpdate: true
            }
        },
        computed: {
            ...mapGetters('notifications', {
                notifications: 'list',
                notificationLoading: 'isLoading',
                notificationLoaded: 'loaded',
                notificationTotal: 'total'
            })
        },
        methods: {
            ...mapActions('notifications', {
                getNotifications: 'list',
                deleteNotifications: 'delete',
                readOneNotification: 'setRead',
                readAllNotifications: 'setReadAll'
            }),
            deleteAll() {
                this.deleteNotifications().then(() => {
                    this.forceUpdate = !this.forceUpdate
                })
            },
            readAll() {
                this.readAllNotifications().then(() => {
                    this.forceUpdate = !this.forceUpdate
                })
            },
            readNotification(notification_id) {
                this.readOneNotification(notification_id).then(() => {
                    this.forceUpdate = !this.forceUpdate
                })
            }
        }
    }
</script>
